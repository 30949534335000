import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    id: String
  }

  toggleEmbellishment () {
    const dropdown = document.getElementById(this.idValue)
    const input = dropdown.querySelector("input[type='checkbox']")
    input.checked = !input.checked
  }

  focusInitialField () {
    setTimeout(function () { document.getElementById('initials').focus() }, 200)
  }

  removeInitials () {
    document.getElementById('initials').value = null
  }
}
